import * as ES6Promise from "es6-promise";
ES6Promise.polyfill();

import Dev from './global/Dev';
import Header from './global/Header';
import SearchForm from './global/SearchForm';
import Intro from './global/Intro';
import LazyLoad from './andreasoby/controllers/Lazyload';

interface ModuleToLoad {
  selector: string,
  promise: any
}

// @ts-ignore
window.loadPage = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Page" */ './pages/Page');
}
// @ts-ignore
window.loadFront = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Front" */ './pages/Front');
}
// @ts-ignore
window.loadArtist = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Artist" */ './pages/Artist');
}
// @ts-ignore
window.loadExhibition = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Exhibition" */ './pages/Exhibition');
}
// @ts-ignore
window.loadProduct = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Product" */ './pages/Product');
}
// @ts-ignore
window.loadPrint = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Print" */ './pages/Print');
}
// @ts-ignore
window.loadPublication = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Publication" */ './pages/Publication');
}
// @ts-ignore
window.loadAbout = async (): Promise<any> => {
  return await import(/* webpackChunkName: "About" */ './pages/About');
}
// @ts-ignore
window.loadViewingRoom = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ViewingRoom" */ './pages/ViewingRoom');
}
// @ts-ignore
window.loadAttachment = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Attachment" */ './pages/Attachment');
}
// @ts-ignore
window.loadArtistOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ArtistOverview" */ './pages/ArtistOverview');
}
// @ts-ignore
window.loadExhibitionOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ExhibitionOverview" */ './pages/ExhibitionOverview');
}
// @ts-ignore
window.loadPageOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "PageOverview" */ './pages/PageOverview');
}
// @ts-ignore
window.loadEditionOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "EditionOverview" */ './pages/EditionOverview');
}
// @ts-ignore
window.loadProductGridOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ProductGridOverview" */ './pages/ProductGridOverview');
}
// @ts-ignore
window.loadPrintOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "PrintOverview" */ './pages/PrintOverview');
}
// @ts-ignore
window.loadPublicationOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "PublicationOverview" */ './pages/PublicationOverview');
}
// @ts-ignore
window.loadNewsOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "NewsOverview" */ './pages/NewsOverview');
}
// @ts-ignore
window.loadTextPage = async (): Promise<any> => {
  return await import(/* webpackChunkName: "TextPage" */ './pages/Text');
}
// @ts-ignore
window.loadCartPage = async (): Promise<any> => {
  return await import(/* webpackChunkName: "CartPage" */ './pages/Cart');
}
// @ts-ignore
window.loadCheckoutPage = async (): Promise<any> => {
  return await import(/* webpackChunkName: "CheckoutPage" */ './pages/Checkout');
}
// @ts-ignore
window.loadSearchResults = async (): Promise<any> => {
  return await import(/* webpackChunkName: "SearchResults" */ './pages/SearchResults');
}

// @ts-ignore
window.loadPublicationTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "PublicationTeaser" */ './teasers/Publication');
}
// @ts-ignore
window.loadTextTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "TextTeaser" */ './teasers/Text');
}
// @ts-ignore
window.loadNewsTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "NewsTeaser" */ './teasers/News');
}
// @ts-ignore
window.loadExhibitionTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ExhibitionTeaser" */ './teasers/Exhibition');
}
// @ts-ignore
window.loadEditionTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "EditionTeaser" */ './teasers/Edition');
}
// @ts-ignore
window.loadPrintTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "PrintTeaser" */ './teasers/Print');
}
// @ts-ignore
window.loadArtistTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ArtistTeaser" */ './teasers/Artist');
}
// @ts-ignore
window.loadDefaultTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "DefaultTeaser" */ './teasers/Default');
}

// @ts-ignore
window.loadPopUp = async (): Promise<any> => {
  return await import(/* webpackChunkName: "PopUp" */ './global/PopUp');
}

// @ts-ignore
window.loadText = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Text" */ './modules/Text');
}
// @ts-ignore
window.loadQuote = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Quote" */ './modules/Quote');
}
// @ts-ignore
window.loadWorks = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Works" */ './modules/Works');
}
// @ts-ignore
window.loadHeadline = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Headline" */ './modules/Headline');
}
// @ts-ignore
window.loadVideo = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Video" */ './modules/Video');
}
// @ts-ignore
window.loadSlideshow = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Slideshow" */ './modules/Slideshow');
}
// @ts-ignore
window.loadColumns = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Columns" */ './modules/Columns');
}
// @ts-ignore
window.loadPersons = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Persons" */ './modules/Persons');
}
// @ts-ignore
window.loadNewsletter = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Newsletter" */ './modules/Newsletter');
}
// @ts-ignore
window.loadInquire = async (): Promise<any> => {
  return await import(/* webpackChunkName: "Inquire" */ './modules/Inquire');
}
// @ts-ignore
window.loadWorkSlideshow = async (): Promise<any> => {
  return await import(/* webpackChunkName: "WorkSlideshow" */ './modules/WorkSlideshow');
}


class Main {

  private _lazy_load: LazyLoad;

  constructor() {

    document.addEventListener('DOMContentLoaded', () => this.init());

  }


  private init() {

    if ( !document.body.classList.contains( 'wp-admin' ) ) {
     
      this._lazy_load = new LazyLoad();
      
      this.setupContent();
      this.checkIfTouch();
    
      new Intro();
      new Header();
      new SearchForm();
      new Dev();

      // Add custom cursor if user isn't using a touch device
      if ( this.checkIfTouch() ) {
        
        document.body.classList.add( 'touch-device' );
        
      }
      
    }
    
  }


  private checkIfTouch() {

    try {

      document.createEvent("TouchEvent");
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      return true;

    } catch (e) {

      return false;

    }  

  }
  
  
  private setupContent() {
    
    const modules: Array<ModuleToLoad> = [
      { selector: '.c-pop-up', promise: 'loadPopUp' },
      { selector: '.c-page', promise: 'loadPage' },
      { selector: '.c-artist-overview', promise: 'loadArtistOverview' },
      { selector: '.c-exhibition-overview', promise: 'loadExhibitionOverview' },
      { selector: '.c-page-overview', promise: 'loadPageOverview' },
      { selector: '.c-edition-overview', promise: 'loadEditionOverview' },
      { selector: '.c-product-grid-overview', promise: 'loadProductGridOverview' },
      { selector: '.c-print-overview', promise: 'loadPrintOverview' },
      { selector: '.c-publication-overview', promise: 'loadPublicationOverview' },
      { selector: '.c-news-overview', promise: 'loadNewsOverview' },
      { selector: '.c-search-results', promise: 'loadSearchResults' },
      { selector: '.c-text-page', promise: 'loadTextPage' },
      { selector: '.c-publication-teaser', promise: 'loadPublicationTeaser' },
      { selector: '.c-text-teaser', promise: 'loadTextTeaser' },
      { selector: '.c-news-teaser', promise: 'loadNewsTeaser' },
      { selector: '.c-exhibition-teaser', promise: 'loadExhibitionTeaser' },
      { selector: '.c-edition-teaser', promise: 'loadEditionTeaser' },
      { selector: '.c-print-teaser', promise: 'loadPrintTeaser' },
      { selector: '.c-artist-teaser', promise: 'loadArtistTeaser' },
      { selector: '.c-post-teaser', promise: 'loadDefaultTeaser' },
      { selector: '.c-front', promise: 'loadFront' },
      { selector: '.c-artist', promise: 'loadArtist' },
      { selector: '.c-exhibition', promise: 'loadExhibition' },
      { selector: '.c-product', promise: 'loadProduct' },
      { selector: '.c-print', promise: 'loadPrint' },
      { selector: '.c-publication', promise: 'loadPublication' },
      { selector: '.c-about', promise: 'loadAbout' },
      { selector: '.c-cart', promise: 'loadCartPage' },
      { selector: '.c-checkout', promise: 'loadCheckoutPage' },
      { selector: '.c-text', promise: 'loadText' },
      { selector: '.c-quote', promise: 'loadQuote' },
      { selector: '.c-works', promise: 'loadWorks' },
      { selector: '.c-viewing-room', promise: 'loadViewingRoom' },
      { selector: '.c-attachment', promise: 'loadAttachment' },
      { selector: '.c-slideshow', promise: 'loadSlideshow' },
      { selector: '.c-columns', promise: 'loadColumns' },
      { selector: '.c-persons', promise: 'loadPersons' },
      { selector: '.c-newsletter', promise: 'loadNewsletter' },
      { selector: '.c-headline', promise: 'loadHeadline' },
      { selector: '.c-media__video-container', promise: 'loadVideo' },
      { selector: '.c-inquire', promise: 'loadInquire' },
      { selector: '.c-work-slideshow', promise: 'loadWorkSlideshow' },
    ];

    const queue: Array<any> = [];
    for ( let i: number = 0; i < modules.length; i++ ) {

      const element: HTMLElement = document.querySelector( modules[ i ].selector );
      //@ts-ignore
      if ( element ) queue.push( window[modules[ i ].promise]() );

    }

    Promise.all( queue ).then( ( modules: any ) => {
      
      for ( let i: number = 0; i < modules.length; i++ ) {
        
        // Get dynamic constructor name
        const name: string = Object.keys(modules[ i ] )[0];
        new modules[ i ][ name ];

      }

      this._lazy_load.updateContent();

    });

  }

}

new Main();