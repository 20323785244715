export default class SearchForm {

  
  private _search_input: HTMLInputElement = document.querySelector( '.c-search-form__input' );

  constructor() {

    const search_activate: HTMLElement = document.querySelector( '.c-main-nav__link--search' );
    if ( search_activate ) search_activate.addEventListener( 'click', ( event: Event ) => this.activateSearch( event ) );

    const search_deactivate: HTMLElement = document.querySelector( '.c-search-form__close' );
    if ( search_deactivate ) search_deactivate.addEventListener( 'click', ( event: Event ) => this.deactivateSearch( event ) );

  }


  private activateSearch( event?: Event ) {
    
    if ( event ) event.preventDefault();

    document.body.classList.add( 's-search-active' );
    if ( this._search_input ) this._search_input.focus();

    return false;

  }


  private deactivateSearch( event?: Event ) {
    
    if ( event ) event.preventDefault();

    document.body.classList.remove( 's-search-active' );

    return false;

  }

}