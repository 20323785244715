import '../../../css/global/lazyload.css';

// @ts-ignore
// require('intersection-observer');

export default class LazyLoad {

    private _observer: IntersectionObserver;
    
    constructor() {

        this.setupObserver();
        window.addEventListener( 'disableLazyLoad', () => this.disableLazyLoad() );
        window.addEventListener( 'enableLazyLoad', () => this.enableLazyLoad() );
        
    }


    private setupObserver() {

        var options: any = {

            rootMargin: '100%',
            threshold: 0

        };
          
        this._observer = new IntersectionObserver( ( entries, observer ) => {

            for ( let i: number = 0; i < entries.length; i++ ) {
                
                if ( entries[ i ].isIntersecting ) {

                    if ( entries[ i ].target.classList.contains( 'c-lazyload--image' ) ) {
                        
                        
                        if ( !entries[ i ].target.hasAttribute( 'src') ) {
                            
                            const src: string = entries[ i ].target.getAttribute( 'data-src' );
                            if ( src ) {
                                
                                entries[ i ].target.setAttribute( 'src', src );
                            }
                            
                        }
                        
                        if ( !entries[ i ].target.hasAttribute( 'srcset') ) {
                            
                            const srcset: string = entries[ i ].target.getAttribute( 'data-srcset' );
                            if ( srcset ) {
                                
                                entries[ i ].target.setAttribute( 'srcset', srcset );
                                
                            }
                        }

                        observer.unobserve( entries[ i ].target );


                    } else if ( entries[ i ].target.classList.contains( 'c-lazyload--video' ) ) {

                        const target: HTMLVideoElement = <HTMLVideoElement>entries[ i ].target;

                        if ( !target.classList.contains('s-disabled') ) {

                            const source_elements: NodeListOf<HTMLSourceElement> = target.querySelectorAll( 'source' );
                            if ( source_elements && source_elements[ 0 ] && !source_elements[ 0 ].hasAttribute( 'src' ) ) {

                                for ( let j: number = 0; j < source_elements.length; j++ ) {
                                    
                                    if ( !source_elements[ j ].hasAttribute( 'src' ) && source_elements[ j ].dataset.src ) {
                                        
                                        source_elements[ j ].setAttribute( 'src', source_elements[ j ].dataset.src)
                                        
                                    }
                                }

                                target.load();
                        
                            }

                            const poster: string = target.dataset.poster;
                            if ( poster ) target.setAttribute( 'poster', poster );

                            if ( !target.classList.contains( 'c-lazyload--video--first-frame-queue' ) ) {
                                
                                target.play();

                            } else if ( target.classList.contains( 'c-lazyload--video--first-frame-queue' )
                            || target.classList.contains( 'c-lazyload--video--first-frame' ) ) {

                                // Showing first frame of video in paused state.
                                // Checks for metadata. If no metadata is available IE11 throws an error.
                                // readyState returns 1 if meta data is retrieved
                                // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
                                // https://stackoverflow.com/a/31390386
                                if ( target.readyState >= 1 ) {

                                    target.play();
                                    target.currentTime = 0;
                                    target.pause();

                                // } else if ( document.documentElement.classList.contains( 'b-ie') ) {
                                    
                                //     console.log('ie');
                                //     target.play();
                                //     target.currentTime = 0;
                                //     target.pause();
                                
                                } else {

                                    target.addEventListener( 'loadeddata', function() {

                                        console.log('loadeddata');
                                        target.play();
                                        target.currentTime = 0;
                                        target.pause();
        
                                    } );
                                }
                                
                            }
                            
                            if ( target.classList.contains( 'c-lazyload--video--first-frame' ) ) {

                                observer.unobserve( entries[ i ].target );

                            }


                            if ( target.classList.contains( 'c-lazyload--video--first-frame-queue' ) ) {
                                
                                if ( target.readyState >= 2 ) {

                                    target.play();

                                } else {
                                    
                                    target.play();
                                    target.addEventListener( 'loadeddata', function() {

                                        target.play();
        
                                    } );

                                }

                            }

                        }

                    }

                } else if ( !entries[ i ].isIntersecting ) {

                    if ( entries[ i ].target.classList.contains( 'c-lazyload--video' ) ) {

                        const target: HTMLVideoElement = <HTMLVideoElement>entries[ i ].target;
                        if ( target.hasAttribute( 'muted' ) && target.hasAttribute( 'loop' ) ) {
                            
                            target.pause();

                        }

                    }

                }

            }

        }, options );

    }


    public updateContent() {
   
        const img_targets: NodeListOf<HTMLImageElement> = document.querySelectorAll( '.c-lazyload--image' );
        for ( let i: number = 0; i < img_targets.length; i++ ) {
            
            img_targets[ i ].addEventListener( 'load', () => { img_targets[ i ].classList.add( 's-loaded' ) } );
            this._observer.observe( img_targets[ i ] );
            
        }
        
        const video_targets: NodeListOf<HTMLVideoElement> = document.querySelectorAll( '.c-lazyload--video' );
        for ( let i: number = 0; i < video_targets.length; i++ ) {
            
            video_targets[ i ].classList.remove('s-disabled');
            this._observer.observe( video_targets[ i ] );
            
        }
        
    }
    
    private enableLazyLoad() {

        this.updateContent();

    }

    private disableLazyLoad() {

        const img_targets: NodeListOf<HTMLImageElement> = document.querySelectorAll( '.c-lazyload--image' );
        for ( let i: number = 0; i < img_targets.length; i++ ) {

            this._observer.unobserve( img_targets[ i ] );

        }

        const video_targets: NodeListOf<HTMLVideoElement> = document.querySelectorAll( '.c-lazyload--video' );
        for ( let i: number = 0; i < video_targets.length; i++ ) {

            const source_elements: NodeListOf<HTMLSourceElement> = video_targets[ i ].querySelectorAll( 'source' );
            for ( let j: number = 0; j < source_elements.length; j++ ) {

                source_elements[ j ].removeAttribute('src');

            }
            
            video_targets[ i ].classList.add('s-disabled');
            this._observer.unobserve( video_targets[ i ] );
            console.log('pause video');
            video_targets[ i ].pause();
            video_targets[ i ].preload = 'none';
            video_targets[ i ].setAttribute( 'preload', 'none' );



        }

    } 

}