export default class Intro {


  private _intro_element: HTMLElement = document.querySelector( '.c-intro' );


  constructor() {

    if ( this._intro_element ) {
      
      const session_storage = window.sessionStorage;
      const intro_shown: string = session_storage.getItem( 'intro_shown' );
      
      if ( !intro_shown ) {
        this._intro_element.classList.add( 's-ready' );
        
        this._intro_element.classList.add( 's-ready' );
        this._intro_element.addEventListener( 'click', () => this.hideIntro() );
        setTimeout(() => this.hideIntro(), 2000);

        session_storage.setItem( 'intro_shown', 'true' );

      } else {

        this._intro_element.classList.add( 's-hidden' );
        document.body.classList.add( 's-show-content' );

      }
      
    } else {
      
      document.body.classList.add( 's-show-content' );
      
    }
    
  }
  
  
  private hideIntro() {
    
    this._intro_element.classList.add( 's-hide' );
    setTimeout(() => this._intro_element.classList.add( 's-hidden' ), 1000);

  }

}